import * as yup from 'yup';

export const createStepOneValidationSchema = (
  tenants: string[],
): yup.ObjectSchema<object, yup.AnyObject, object, ''> => {
  return yup.object().shape({
    sourceTenant: yup
      .string()
      .oneOf(tenants, 'Value must be one of existing tenants')
      .notOneOf([yup.ref('targetTenant'), null], 'Source and target tenant cant match')
      .required('Please choose source tenant to continue'),
    targetTenant: yup
      .string()
      .oneOf(tenants, 'Value must be one of existing tenants')
      .notOneOf([yup.ref('sourceTenant'), null], 'Source and target tenant cant match')
      .required('Please choose target tenant to continue'),
    deviceSerialNumbers: yup.string().required('Please add valid device serial numbers to continue'),
  });
};

export const LogInValidationSchema = yup.object().shape({
  username: yup.string().trim().email('Please enter a valid email').required('This field is required'),
  password: yup.string().required('This field is required'),
});

export const NewPasswordRequiredValidationSchema = yup.object().shape({
  password: yup
    .string()
    .required('This field is required')
    .min(8, 'Your password is too short')
    .test('isValidPass', 'One of the requirements above is not fulfilled', (value: any) => {
      if (!/[A-Z]/.test(value)) return false;
      if (!/[0-9]/.test(value)) return false;
      if (!/[a-z]/.test(value)) return false;
      if (!/["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value)) return false;
      return true;
    }),
  confirmPassword: yup
    .string()
    .required('Confirm Password Required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

export const RequestResetPasswordValidationSchema = yup.object().shape({
  username: yup.string().email('Please enter a valid email').required('This field is required'),
});

export const ResetPasswordValidationSchema = yup.object().shape({
  username: yup.string().email('Please enter a valid email').required('This field is required'),
  password: yup
    .string()
    .required('This field is required')
    .min(8, 'Your password is too short')
    .test('isValidPass', 'One of the requirements above is not fulfilled', (value: any) => {
      if (!/[A-Z]/.test(value)) return false;
      if (!/[0-9]/.test(value)) return false;
      if (!/[a-z]/.test(value)) return false;
      if (!/["!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"]/.test(value)) return false;
      return true;
    }),
  confirmPassword: yup
    .string()
    .required('Confirm Password Required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
  code: yup.string().test('hasOnlyNumbers', 'This field can only have numbers', (value: any) => {
    if (!/[0-9]/.test(value)) return false;
    return true;
  }),
});

export const SmsVerificationSchema = yup.object().shape({
  code: yup.string().test('hasOnlyNumbers', 'This field can only have numbers', (value: any) => {
    if (!/[0-9]/.test(value)) return false;
    return true;
  }),
});
