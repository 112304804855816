import Select, { type ActionMeta, type SingleValue } from 'react-select';

interface IInputTextWithSuggestions<T> {
  results?: T[];
  getOptionLabel: (item: T) => string;
  getOptionValue: (item: T) => string;
  className?: string;
  onChange?: (newValue: SingleValue<T>, actionMeta: ActionMeta<T>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  setTouched?: () => void;
  value?: T;
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  name?: string;
  id?: string;
}

const InputTextWithSuggestions = <T extends object>({
  results = [],
  getOptionLabel,
  getOptionValue,
  className = '',
  onChange,
  onBlur,
  setTouched,
  value,
  placeholder = '',
  disabled = false,
  invalid = false,
  name,
  id,
}: IInputTextWithSuggestions<T>): JSX.Element => {
  const customStyles = {
    control: (base: any) => {
      return {
        ...base,
        boxShadow: '0 0 0 1px white',
        borderColor: !invalid ? '#ddd' : 'red',
        transition: `border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out`,
        '&:hover': {
          borderColor: !invalid ? '#ddd' : 'red',
        },
        '&:focus-within': {
          boxShadow: !invalid ? `0 0 0 0.2rem rgba(70, 186, 186, 0.25)` : '0 0 0 0.2rem #dc354550',
        },
      };
    },
  };

  return (
    <Select
      id={id}
      name={name}
      value={value}
      placeholder={placeholder}
      isDisabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={() => setTouched?.()}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      options={results}
      className={className}
      components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
      styles={customStyles}
    />
  );
};

export default InputTextWithSuggestions;
