import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { axiosInstance } from '../../api/axios';
import { type AxiosError } from 'axios';
import { Formik, type FormikProps, ErrorMessage } from 'formik';
import { type ITransferData } from '../../contexts/TransferContext';
import { Link } from 'react-router-dom';
import { Row, Col, Label, Button } from 'reactstrap';
import { createStepOneValidationSchema } from '../../schemas';
import InputTextWithSuggestions from '../input-text-with-suggestions/InputTextWithSuggestions';

import styles from './styles.module.scss';

interface ITransferStepOne {
  transferData: ITransferData;
  nextStep: () => void;
  handleChangeTransferData: (newTransferData: ITransferData) => void;
}

interface ITenantCode {
  id: number;
  shortcode: string;
}

interface ITenantCodes {
  tenants: ITenantCode[];
}

const SerialNumbersRegex = /^(?:[A-Za-z0-9_-]{0,16}\n)*[A-Za-z0-9_-]{0,16}$/;

const TransferStepOne = ({ transferData, nextStep, handleChangeTransferData }: ITransferStepOne): JSX.Element => {
  const {
    data: tenantCodes,
    error,
    isLoading,
    isError,
    refetch,
  } = useQuery<ITenantCodes, AxiosError>(['tenants-list'], async () => {
    const result = await axiosInstance.get('/transfer_devices_between_tenants/get_list_of_tenants', {
      params: {
        limit: 500,
      },
    });
    return result.data;
  });

  const validationSchema = useMemo(() => {
    return createStepOneValidationSchema(tenantCodes?.tenants.map((tenant) => tenant.shortcode) ?? []);
  }, [tenantCodes?.tenants]);
  if (isError) {
    console.log('tenantCodes', tenantCodes);
    console.log('error', error);
  }

  return (
    <Formik
      initialValues={{ ...transferData }}
      onSubmit={(values) => {
        const { deviceSerialNumbers, sourceTenant, targetTenant } = values;
        handleChangeTransferData({
          deviceSerialNumbers,
          sourceTenant,
          targetTenant,
        });
        nextStep();
      }}
      validationSchema={validationSchema}
    >
      {({
        handleSubmit,
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleBlur,
        setFieldTouched,
      }: FormikProps<ITransferData>) => {
        return (
          <form onSubmit={handleSubmit} autoComplete={'off'}>
            <Row className={styles['step_one-tenants-containers']}>
              <div className={styles['step_one-tenants-container']}>
                <Label className="font-weight-bold mb-1">Source tenant</Label>
                <InputTextWithSuggestions
                  results={tenantCodes?.tenants}
                  disabled={isLoading || isError}
                  onChange={(newValue) => {
                    setFieldValue('sourceTenant', newValue?.shortcode);
                  }}
                  setTouched={() => {
                    setFieldTouched('sourceTenant', true, true);
                  }}
                  onBlur={handleBlur}
                  invalid={errors.sourceTenant !== undefined}
                  placeholder="Choose source tenant"
                  value={tenantCodes?.tenants?.find((tenantResult) => tenantResult.shortcode === values.sourceTenant)}
                  name="sourceTenant"
                  id="sourceTenant"
                  getOptionLabel={(item: ITenantCode): string => {
                    return item.shortcode;
                  }}
                  getOptionValue={(item: ITenantCode): string => {
                    return item.shortcode;
                  }}
                />
                <ErrorMessage component="span" className={styles['error-message-tip']} name={'sourceTenant'} />
              </div>
              <i className="fa-solid fa-arrow-right align-self-end ml-4 mr-4" style={{ color: '#8991a6' }}></i>
              <div className={styles['step_one-tenants-container']}>
                <Label className="font-weight-bold mb-1">Target tenant</Label>
                <InputTextWithSuggestions
                  results={tenantCodes?.tenants}
                  disabled={isLoading || isError}
                  onChange={(newValue) => {
                    setFieldValue('targetTenant', newValue?.shortcode);
                  }}
                  setTouched={() => {
                    setFieldTouched('targetTenant', true, true);
                  }}
                  onBlur={handleBlur}
                  invalid={errors.targetTenant !== undefined}
                  placeholder="Choose target tenant"
                  value={tenantCodes?.tenants?.find((tenantResult) => tenantResult.shortcode === values.targetTenant)}
                  name="targetTenant"
                  id="targetTenant"
                  getOptionLabel={(item: ITenantCode): string => {
                    return item.shortcode;
                  }}
                  getOptionValue={(item: ITenantCode): string => {
                    return item.shortcode;
                  }}
                />
                <ErrorMessage component="span" className={styles['error-message-tip']} name={'targetTenant'} />
              </div>
              {isError ? (
                <span className={styles['error-message-tip']}>
                  {error.message}{' '}
                  <span
                    className={styles['text-decoration-underline']}
                    onClick={() => {
                      void refetch();
                    }}
                  >
                    Try again
                  </span>
                </span>
              ) : null}
            </Row>
            <Col>
              <Label className="font-weight-bold mb-1">Device serial numbers</Label>
              <Row>
                <Row className={styles['step_one-serial-numbers-field']}>
                  <textarea
                    onChange={(e) => {
                      if (!SerialNumbersRegex.test(e.target.value)) {
                        return;
                      }
                      setFieldValue('deviceSerialNumbers', e.target.value);
                    }}
                    rows={20}
                    wrap="hard"
                    onBlur={handleBlur}
                    placeholder={`1P-TDO-000001\n1P-TDO-000001\n ...`}
                    id="deviceSerialNumbers"
                    name="deviceSerialNumbers"
                    value={values.deviceSerialNumbers}
                    className={`textarea-h50 ${styles['device-serial-number-text-area']} ${
                      touched.deviceSerialNumbers === true && errors.deviceSerialNumbers !== undefined
                        ? 'is-invalid'
                        : ''
                    }`}
                  />
                  <ErrorMessage component="span" className={styles['error-message-tip']} name={'deviceSerialNumbers'} />
                </Row>
                <Row className={styles['step_one-serial-numbers-info']}>
                  <Col className={styles['info-icon']}>
                    <i className="fa-solid fa-circle-info"></i>
                  </Col>
                  <Col className="pt-1">
                    <span>If you have multiple serial numbers, enter each in new line</span>
                  </Col>
                </Row>
              </Row>
            </Col>

            <Col className={styles['transfer-control-buttons']}>
              <Link to="/commands/transfer-devices-between-tenants" className="btn btn-secondary">
                Cancel
              </Link>
              <Button disabled={isSubmitting} type="submit" color="primary">
                Preview transfer
              </Button>
            </Col>
          </form>
        );
      }}
    </Formik>
  );
};

export default TransferStepOne;
