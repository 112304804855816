import { Nav, Navbar, NavbarBrand, NavItem } from 'reactstrap';
import styles from './styles.module.scss';

interface IPublicFormWrapper {
  children: React.ReactNode;
}
const PublicFormWrapper = ({ children }: IPublicFormWrapper): JSX.Element => {
  return (
    <div className={styles['app-container']}>
      <Navbar color="dark" dark expand="md" className={`m-0 p-0 ${styles['nav-container']}`}>
        <Nav className="navbar d-flex justify-content-between align-items-center container-fluid m-0 p-0">
          <NavbarBrand href={'/'} className={styles['brand-image']} />
        </Nav>
      </Navbar>
      <div className={styles['content-container']}>
        <div className={styles['centered-container']}>{children}</div>
      </div>
      <Navbar color={'dark'} className={styles['footer-container']}>
        <Nav className="m-auto">
          <NavItem>
            InflowNet Admin App <span className={styles['version-number']}>v{process.env.REACT_APP_VERSION}</span>
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
};

export default PublicFormWrapper;
