import { useContext } from 'react';
import { Button, Card, Input, Label } from 'reactstrap';
import { ErrorMessage, Formik, type FormikProps } from 'formik';
import ChallengeGuard from '../../../components/challenge-guard/ChallengeGuard';
import { AuthContext } from '../../../contexts/AuthContext';
import { NewPasswordRequiredValidationSchema } from '../../../schemas';

import styles from './styles.module.scss';

interface IFormikNewPasswordRequiredProps {
  password: string;
  confirmPassword: string;
}

const NewPasswordRequired: React.FC = () => {
  const { completeNewPassword, isLoading } = useContext(AuthContext);
  return (
    <ChallengeGuard>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        onSubmit={(values) => {
          const { password } = values;
          completeNewPassword(password);
        }}
        validationSchema={NewPasswordRequiredValidationSchema}
      >
        {({ handleSubmit, values, errors, handleChange, handleBlur }: FormikProps<IFormikNewPasswordRequiredProps>) => {
          return (
            <form onSubmit={handleSubmit}>
              <Card className={styles['card-container']}>
                <h1 className="mx-auto mb-4">New password required</h1>
                <div>
                  <span className="font-weight-bold">Requirements</span>
                  <ul className={'pl-2 mt-2 mb-1 ' + styles['list-elements']}>
                    <li>8 characters or more</li>
                    <li>small and large letter (a-z, A-Z)</li>
                    <li>at least one digit (0-9)</li>
                    <li>at least one special character</li>
                  </ul>
                </div>
                <div>
                  <Label className={'font-weight-bold mb-1' + (errors.password !== undefined ? ' text-danger' : '')}>
                    New password
                  </Label>
                  <Input
                    name="password"
                    id="password"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    invalid={errors.password !== undefined}
                  />
                  <ErrorMessage component="span" className={styles['error-message-tip']} name={'password'} />
                </div>
                <div>
                  <Label
                    className={'font-weight-bold mb-1' + (errors.confirmPassword !== undefined ? ' text-danger' : '')}
                  >
                    New password confirmation
                  </Label>
                  <Input
                    name="confirmPassword"
                    id="confirmPassword"
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    invalid={errors.confirmPassword !== undefined}
                  />
                  <ErrorMessage component="span" className={styles['error-message-tip']} name={'confirmPassword'} />
                </div>
                <Button disabled={isLoading} type="submit" color="primary">
                  Update password
                </Button>
              </Card>
            </form>
          );
        }}
      </Formik>
    </ChallengeGuard>
  );
};

export default NewPasswordRequired;
