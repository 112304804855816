import { type CognitoUser } from 'amazon-cognito-identity-js';
import { Amplify, Auth } from 'aws-amplify';
import { createContext, useEffect, useState, type ReactNode } from 'react';
import { AwsConfigAuth } from '../utils/auth';

Amplify.configure({ Auth: AwsConfigAuth });

interface IAuthContextProvider {
  children: ReactNode;
}

interface UseAuth {
  user: CognitoUser | null;
  error: string;
  isLoading: boolean;
  isAuthenticated: boolean;
  signIn: (username: string, password: string) => void;
  signOut: (reason?: string) => void;
  verifySms: (code: string) => void;
  requestResetPassword: (email: string) => void;
  resetPassword: (username: string, password: string, code: string) => void;
  completeNewPassword: (password: string) => void;
  clearAuthState: () => void;
}

const InitialAuthContextValue = {
  user: null,
  isLoading: false,
  isAuthenticated: false,
  error: '',
  signIn: () => {},
  signOut: () => {},
  verifySms: () => {},
  requestResetPassword: () => {},
  resetPassword: () => {},
  completeNewPassword: () => {},
  clearAuthState: () => {},
};

export const AuthContext = createContext<UseAuth>(InitialAuthContextValue);

const AuthContextProvider: ({ children }: IAuthContextProvider) => JSX.Element = ({
  children,
}: IAuthContextProvider) => {
  const [user, setUser] = useState<any>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((result) => {
        setUser(result);
        setIsAuthenticated(true);
      })
      .catch((err) => {
        setUser(null);
        console.warn(err);
      });
  }, []);

  const signIn = (username: string, password: string): void => {
    setIsLoading(true);
    Auth.signIn(username, password)
      .then((user: CognitoUser) => {
        setUser(user);
        if (user.challengeName !== undefined) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
        setError('');
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
        if (err.message === 'Password reset required for the user') {
          window.location.replace(`/reset-password?email=${username}`);
        }
      });
  };

  const signOut = (reason: string = ''): void => {
    setIsLoading(true);
    Auth.signOut()
      .then(() => {
        setUser(null);
        setIsAuthenticated(false);
        setIsLoading(false);
        setError(reason);
      })
      .catch((err) => {
        console.warn(err.message);
        setError(err.message);
        setIsLoading(false);
      });
  };

  const completeNewPassword = (password: string): void => {
    setIsLoading(true);
    Auth.completeNewPassword(user, password)
      .then((result) => {
        setUser(result);
        setIsLoading(false);
        setIsAuthenticated(true);
        setError('');
      })
      .catch((err) => {
        console.warn(err.message);
        setError(err.message);
        setIsLoading(false);
      });
  };

  const requestResetPassword = (username: string): void => {
    setIsLoading(true);
    Auth.forgotPassword(username)
      .then((data) => {
        console.log('data', data);
        window.location.replace(`/reset-password?email=${username}`);
        setError('');
        setIsLoading(false);
      })
      .catch((err) => {
        console.warn(err.message);
        setError(err.message);
        setIsLoading(false);
      });
  };

  const resetPassword = (username: string, password: string, code: string): void => {
    setIsLoading(true);
    Auth.forgotPasswordSubmit(username, code, password)
      .then(() => {
        window.location.replace('/log-in');
        setError('');
        setIsLoading(false);
      })
      .catch((err) => {
        console.warn(err.message);
        setError(err.message);
        setIsLoading(false);
      });
  };

  const verifySms = (code: string): void => {
    setIsLoading(true);
    Auth.confirmSignIn(user, code, 'SMS_MFA')
      .then((user) => {
        setUser(user);
        setIsAuthenticated(true);
        setError('');
        setIsLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  };

  const clearAuthState = (): void => {
    setUser(null);
    setIsAuthenticated(false);
    setError('');
    setIsLoading(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        verifySms,
        signIn,
        signOut,
        requestResetPassword,
        resetPassword,
        completeNewPassword,
        isLoading,
        isAuthenticated,
        error,
        clearAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
