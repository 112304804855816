import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import PublicFormWrapper from '../../components/public-form-wrapper/PublicFormWrapper';
import LogIn from '../../containers/auth/log-in/LogIn';
import NewPasswordRequired from '../../containers/auth/new-password-required/NewPasswordRequired';
import ResetPassword from '../../containers/auth/reset-password/ResetPassword';
import SmsVerification from '../../containers/auth/sms-verification/SmsVerification';
import IdleLogout from '../../containers/auth/idle-signout/IdleSignout';
import PasswordRecovery from '../../containers/auth/password-recovery/PasswordRecovery';

const PublicRoutes: React.FC = () => {
  return (
    <PublicFormWrapper>
      <Routes>
        <Route path="*" element={<Navigate replace to={'/log-in'} />} />
        <Route path="/log-in" element={<LogIn />} />
        <Route path="/new-password-required" element={<NewPasswordRequired />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/sms-verification" element={<SmsVerification />} />
        <Route path="/idle-signout" element={<IdleLogout />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
      </Routes>
    </PublicFormWrapper>
  );
};

export default PublicRoutes;
