import React, { useContext } from 'react';
import { Spinner } from 'reactstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import PublicRoutes from './public_routes/PublicRoutes';
import PrivateRoutes from './private_routes/PrivateRoutes';

const Routes: React.FC = () => {
  const { user, isAuthenticated } = useContext(AuthContext);

  if (user === undefined) {
    return <Spinner className="no-content-spinner" />;
  }
  return <Router>{isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />}</Router>;
};

export default Routes;
