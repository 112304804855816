import { useContext, useEffect } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Label } from 'reactstrap';

import styles from './styles.module.scss';

const IdleSignout = (): JSX.Element => {
  const { error, clearAuthState } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (error !== 'idle-signout') {
      navigate('/log-in');
    }
  }, [navigate, error]);
  const handleNavigateToLogIn = (): void => {
    clearAuthState();
    navigate('/log-in');
  };
  return (
    <Card className={styles['card-container']}>
      <h1 className="mx-auto mb-4">Logged out</h1>
      <Label>You have been logged out automatically after 15 minutes of inactivity.</Label>
      <Button color="primary" onClick={handleNavigateToLogIn}>
        Go to login
      </Button>
    </Card>
  );
};

export default IdleSignout;
