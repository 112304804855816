import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { axiosInstance } from '../../api/axios';
import { type AxiosError } from 'axios';
import { type ITransferData } from '../../contexts/TransferContext';
import { Button, Input, Row, Label, Col, Badge, Spinner, Alert } from 'reactstrap';
import { groupBy, mapDeviceStateToBgColor } from '../../utils';
import DeviceSerialNumberListItem from '../device-serial-number-list-item/DeviceSerialNumberListItem';

import styles from './styles.module.scss';

interface ITransferStepTwo {
  transferData: ITransferData;
  previousStep: () => void;
  handleSubmitTransferInformation: (devices: IVerifiedDevice[] | null) => Promise<void>;
}

export type DeviceStatus = 'transferable' | 'not_transferable' | 'not_found';

export interface IVerifiedDevice {
  serial_number: string;
  status: DeviceStatus;
  details: string | null;
}

interface IVerifiedDeviceList {
  statuses: IVerifiedDevice[];
}

const TransferStepTwo = ({
  transferData,
  previousStep,
  handleSubmitTransferInformation,
}: ITransferStepTwo): JSX.Element => {
  const [waitingForResult, setWaitingForResult] = useState(false);
  const [errorWhileTransfer, setErrorWhileTransfer] = useState<string | null>(null);

  const {
    data: verifiedDevices,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ['verify-devices', transferData],
    async () => {
      const result = await axiosInstance.post(
        '/transfer_devices_between_tenants/verify_devices_to_transfer_between_tenants',
        {
          source_tenant: transferData.sourceTenant,
          target_tenant: transferData.targetTenant,
          serial_numbers: [...new Set(transferData.deviceSerialNumbers.split(/[\r\n]+/).filter((a) => a.length > 0))],
        },
      );
      return result.data;
    },
    {
      cacheTime: 0,
      refetchOnMount: true,
      select: (data: IVerifiedDeviceList) => groupBy<IVerifiedDevice>(data.statuses, (v: IVerifiedDevice) => v.status),
      onError: (error: AxiosError) => error,
    },
  );

  const renderTransferList: IVerifiedDevice[] | null = useMemo(() => {
    if (verifiedDevices === undefined) {
      return null;
    }
    const deviceTableElements = [];
    if (verifiedDevices.transferable !== undefined) {
      deviceTableElements.push(...verifiedDevices.transferable);
    }
    if (verifiedDevices.not_transferable !== undefined) {
      deviceTableElements.push(...verifiedDevices.not_transferable);
    }
    if (verifiedDevices.not_found !== undefined) {
      deviceTableElements.push(...verifiedDevices.not_found);
    }
    return deviceTableElements;
  }, [verifiedDevices]);

  return (
    <>
      <Col>
        {errorWhileTransfer === null ? null : (
          <Alert color="danger" name="idle-alert">
            {errorWhileTransfer}
          </Alert>
        )}
      </Col>
      <Row className={styles['step_one-tenants-containers']}>
        <div className={styles['step_one-tenants-container']}>
          <Label className="font-weight-bold mb-1">Source tenant</Label>
          <Input value={transferData.sourceTenant} readOnly disabled name="sourceTenant" id="sourceTenant" />
        </div>
        <i className="fa-solid fa-arrow-right align-self-end ml-4 mr-4" style={{ color: '#8991a6' }}></i>
        <div className={styles['step_one-tenants-container']}>
          <Label className="font-weight-bold mb-1">Target tenant</Label>
          <Input value={transferData.targetTenant} disabled readOnly name="targetTenant" id="targetTenant" />
        </div>
      </Row>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Col>
            <Col>
              <Label className="font-weight-bold mb-1">Device serial numbers</Label>
              {verifiedDevices !== undefined
                ? Object.entries(verifiedDevices).map((devices, index) => {
                    return (
                      <Badge key={index} className={`text-white p-1 ml-2 ${mapDeviceStateToBgColor(devices[0])}`}>
                        {devices[1].length}
                      </Badge>
                    );
                  })
                : null}
            </Col>
            <Col>
              {error !== null ? (
                <>
                  <Alert color="danger" name="idle-alert">
                    {error.message}
                  </Alert>
                  <Button
                    disabled={isLoading}
                    onClick={() => {
                      refetch()
                        .then(() => null)
                        .catch(() => null);
                    }}
                    className="btn btn-secondary"
                  >
                    Retry
                  </Button>
                </>
              ) : null}
              {renderTransferList?.map((verifiedDevice, index) => {
                return <DeviceSerialNumberListItem key={index} device={verifiedDevice} />;
              })}
            </Col>
          </Col>
        </>
      )}
      <Col className={styles['transfer-control-buttons']}>
        <Button
          disabled={waitingForResult}
          onClick={() => {
            previousStep();
          }}
          className="btn btn-secondary"
        >
          Back
        </Button>
        <Row>
          <Button
            className="mr-1"
            disabled={
              waitingForResult ||
              verifiedDevices === undefined ||
              verifiedDevices?.transferable === undefined ||
              verifiedDevices?.transferable.length === 0
            }
            onClick={() => {
              if (verifiedDevices === undefined) {
                return;
              }
              setWaitingForResult(true);
              setErrorWhileTransfer(null);
              handleSubmitTransferInformation([...verifiedDevices?.transferable]).catch((error: AxiosError) => {
                setErrorWhileTransfer(error?.message);
                setWaitingForResult(false);
              });
            }}
            color="primary"
          >
            {`Transfer only valid devices (${
              verifiedDevices?.transferable !== undefined ? verifiedDevices.transferable?.length : '0'
            })`}
          </Button>
          <Button
            className={`ml-1 ${styles['all-devices-button']}`}
            disabled={waitingForResult || renderTransferList === null || renderTransferList.length === 0}
            onClick={() => {
              if (renderTransferList === null) {
                return;
              }
              setWaitingForResult(true);
              setErrorWhileTransfer(null);
              handleSubmitTransferInformation([...renderTransferList]).catch((error: AxiosError) => {
                setErrorWhileTransfer(error?.message);
                setWaitingForResult(false);
              });
            }}
          >
            {`Transfer all devices (${renderTransferList != null ? renderTransferList?.length : '0'})`}
          </Button>
        </Row>
      </Col>
    </>
  );
};

export default TransferStepTwo;
