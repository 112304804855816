import { Badge, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { mapDeviceStateToBgColor, mapDeviceStateToText } from '../../utils';
import { type IVerifiedDevice } from '../transfer-steps/TransferStepTwo';
import DeviceIcon from '../../static_assets/svg/device_normal.svg';

import styles from './styles.module.scss';

const DeviceSerialNumberListItem = ({ device }: { device: IVerifiedDevice }): JSX.Element => {
  return (
    <Col key={device.serial_number} className="mt-2 position-relative">
      <img alt="device-img" src={DeviceIcon} className="pr-1" />
      <Label className="mb-1">{device.serial_number}</Label>
      {device.details != null && device.details?.length > 0 ? (
        <UncontrolledTooltip
          className={styles.tooltip}
          target={`badge-${device.serial_number}`}
          name={`tooltip-${device.serial_number}`}
          placement="right"
        >
          <span className="arrow tooltip-arrow"></span>
          {device.details}
        </UncontrolledTooltip>
      ) : null}
      <Badge
        id={`badge-${device.serial_number}`}
        name={`badge-${device.serial_number}`}
        className={`text-white p-1 ml-2 cursor-pointer ${mapDeviceStateToBgColor(device.status)}`}
      >
        {mapDeviceStateToText(device.status)}
      </Badge>
    </Col>
  );
};

export default DeviceSerialNumberListItem;
