import Axios, { type InternalAxiosRequestConfig } from 'axios';

const axiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const configureInterceptors = (token?: string): void => {
  axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
      if (typeof token === 'string') {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    async (error) => {
      return await Promise.reject(error);
    },
  );
};

export { axiosInstance, configureInterceptors };
