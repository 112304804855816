import { useContext } from 'react';
import { ErrorMessage, Formik, type FormikProps } from 'formik';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, Input, Label } from 'reactstrap';
import { AuthContext } from '../../../contexts/AuthContext';
import { RequestResetPasswordValidationSchema } from '../../../schemas';

import styles from './styles.module.scss';

interface IFormikPasswordRecoveryProps {
  username: string;
}

const PasswordRecovery: React.FC = () => {
  const { requestResetPassword, error, isLoading } = useContext(AuthContext);

  return (
    <Formik
      initialValues={{
        username: '',
      }}
      onSubmit={(values) => {
        const { username } = values;
        console.log('email', username);
        requestResetPassword(username);
      }}
      validationSchema={RequestResetPasswordValidationSchema}
    >
      {({ handleSubmit, touched, errors, handleChange, handleBlur }: FormikProps<IFormikPasswordRecoveryProps>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles['card-container']}>
              <h2 className="mx-auto mb-4">Password recovery</h2>

              {error.length > 0 ? (
                <Alert color="danger" name="idle-alert">
                  {error}
                </Alert>
              ) : null}

              <div className={styles['info-text-container']}>
                <Label className={styles['info-text']}>
                  Get a secure link sent to your email, so you can log in instantly. After that, you may set a new
                  password.
                </Label>
              </div>
              <div>
                <Label
                  className={
                    'font-weight-bold mb-1' +
                    (touched.username === true && errors.username !== undefined ? ' text-danger' : '')
                  }
                >
                  Email
                </Label>
                <Input
                  name="username"
                  id="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={touched.username === true && errors.username !== undefined}
                />
                <ErrorMessage component="span" className={styles['error-message-tip']} name={'username'} />
              </div>

              <Button disabled={isLoading} type="submit" color="primary">
                Send me the link
              </Button>
              <Link className="align-self-center font-weight-bold my-1" to="/log-in">
                Back to login
              </Link>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default PasswordRecovery;
