import { useContext, useEffect } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const ChallengeGuard = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.challengeName === undefined) {
      navigate('/');
    }
  }, [user, navigate]);

  return <>{children}</>;
};

export default ChallengeGuard;
