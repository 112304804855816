import { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import TransferProgress from '../transfer-progress/TransferProgress';
import { TransferContext } from '../../contexts/TransferContext';

import styles from './styles.module.css';

const TransferDevicesBetweenTenantsTransfer: React.FC = () => {
  const { renderCurrentStep } = useContext(TransferContext);
  return (
    <Row className={styles.content + ' d-flex flex-column'}>
      <Col className={styles.header}>
        <h1 className={styles['header-text']}>Transfer devices between tenants</h1>
      </Col>
      <Col className={styles.body}>
        <TransferProgress />
        <Col className={styles['steps-container']}>{renderCurrentStep}</Col>
      </Col>
    </Row>
  );
};

export default TransferDevicesBetweenTenantsTransfer;
