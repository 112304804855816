import TransferStepOne from '../components/transfer-steps/TransferStepOne';
import TransferStepTwo, { type IVerifiedDevice } from '../components/transfer-steps/TransferStepTwo';
import TransferStepThree from '../components/transfer-steps/TransferStepThree';
import { useLocation, useNavigate } from 'react-router-dom';
import { createContext, useState, useMemo, useCallback, type ReactNode } from 'react';
import { axiosInstance } from '../api/axios';
import { type AxiosResponse } from 'axios';
import { type ITransferListItem } from '../components/transfer-list-item/TransferListItem';

interface ITransferContextProvider {
  children: ReactNode;
}

export const noop = (): void => {};

export type TransferType = 'only_valid' | 'all';

export interface ITransferData {
  sourceTenant: string;
  targetTenant: string;
  deviceSerialNumbers: string;
}

export interface ITransferContextValue {
  transferData: ITransferData;
  currentStep: number;
  renderCurrentStep: JSX.Element;
  initNewTransfer: () => void;
  nextStep: () => void;
  previousStep: () => void;
  handlePreviewTransferInformation: (transferID: string) => void;
  handleSubmitTransferInformation: (devices: IVerifiedDevice[] | null) => Promise<void>;
}

const defaultTransferData: ITransferData = {
  sourceTenant: '',
  targetTenant: '',
  deviceSerialNumbers: '',
};

const InitialTransferContextValue = {
  transferData: defaultTransferData,
  currentStep: 1,
  renderCurrentStep: <></>,
  initNewTransfer: noop,
  nextStep: noop,
  previousStep: noop,
  handlePreviewTransferInformation: noop,
  handleSubmitTransferInformation: async () => await Promise.reject(Error()),
};

export const TransferContext = createContext<ITransferContextValue>(InitialTransferContextValue);

const TransferContextProvider: ({ children }: ITransferContextProvider) => JSX.Element = ({
  children,
}: ITransferContextProvider) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [transferData, setTransferData] = useState<ITransferData>(defaultTransferData);
  const [currentStep, setCurrentStep] = useState(1);

  const initNewTransfer = useCallback(() => {
    setTransferData(defaultTransferData);
    setCurrentStep(1);
  }, [setTransferData]);

  const handleChangeTransferData = useCallback(
    (newTransferData: ITransferData): void => {
      setTransferData({ ...transferData, ...newTransferData });
    },
    [transferData, setTransferData],
  );

  const nextStep = useCallback((): void => {
    setCurrentStep(currentStep + 1);
  }, [currentStep, setCurrentStep]);

  const previousStep = useCallback((): void => {
    setCurrentStep(currentStep - 1);
  }, [currentStep, setCurrentStep]);

  const handlePreviewTransferInformation = useCallback(
    (transferID: string): void => {
      navigate(`/commands/transfer-devices-between-tenants/transfer#${transferID}`);
    },
    [navigate],
  );

  const handleSubmitTransferInformation = useCallback(
    async (devices: IVerifiedDevice[] | null): Promise<void> => {
      if (devices === null) {
        return;
      }
      await axiosInstance
        .post('/transfer_devices_between_tenants/transfer_devices_between_tenants', {
          source_tenant: transferData.sourceTenant,
          target_tenant: transferData.targetTenant,
          serial_numbers: [...devices.map((device) => device.serial_number)],
        })
        .then((result: AxiosResponse<ITransferListItem>) => {
          window.location.replace(`#${result.data.id}`);
        });
    },
    [transferData.sourceTenant, transferData.targetTenant],
  );

  const renderCurrentStep = useMemo(() => {
    if (location.hash.length > 0) {
      return <TransferStepThree setCurrentStep={setCurrentStep} />;
    }

    switch (currentStep) {
      case 1: {
        return (
          <TransferStepOne
            transferData={transferData}
            nextStep={nextStep}
            handleChangeTransferData={handleChangeTransferData}
          />
        );
      }
      case 2: {
        return (
          <TransferStepTwo
            transferData={transferData}
            previousStep={previousStep}
            handleSubmitTransferInformation={handleSubmitTransferInformation}
          />
        );
      }
      case 3:
      case 4: {
        return <TransferStepThree setCurrentStep={setCurrentStep} />;
      }
      default: {
        return <h1>Error occured during the steps</h1>;
      }
    }
  }, [
    currentStep,
    handleChangeTransferData,
    handleSubmitTransferInformation,
    location.hash,
    setCurrentStep,
    nextStep,
    previousStep,
    transferData,
  ]);

  return (
    <TransferContext.Provider
      value={{
        transferData,
        currentStep,
        renderCurrentStep,
        initNewTransfer,
        nextStep,
        previousStep,
        handlePreviewTransferInformation,
        handleSubmitTransferInformation,
      }}
    >
      {children}
    </TransferContext.Provider>
  );
};

export default TransferContextProvider;
