import { useEffect, useState } from 'react';
import { mapPathToName } from '../../../utils';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import TransferContextProvider from '../../../contexts/TransferContext';

import './styles.css';

interface IBreadcrumbs {
  name: string | undefined;
  path: string;
}

const TransferDevicesBetweenTenants: React.FC = () => {
  const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumbs[]>([]);
  const location = useLocation();

  useEffect(() => {
    const { pathname, hash } = location;
    let currentLocationPath = '';
    let filteredPath = pathname;

    while (filteredPath[filteredPath.length - 1] === '/') {
      filteredPath = filteredPath.slice(0, -1);
    }

    const breadcrumbs = filteredPath
      .split('/')
      .map((crumb) => {
        currentLocationPath += crumb + '/';
        return {
          name: mapPathToName(crumb),
          path: currentLocationPath,
        };
      })
      .filter((breadcrumb) => breadcrumb.name !== undefined);
    if (hash.length > 0) {
      const lastPath = breadcrumbs[breadcrumbs.length - 1].path;
      breadcrumbs[breadcrumbs.length - 1].path = lastPath.substring(0, lastPath.length - 1) + hash;
    }
    setBreadcrumbItems(breadcrumbs);
  }, [location]);

  return (
    <TransferContextProvider>
      <div className="main-cointainer">
        <Breadcrumb>
          {breadcrumbItems.map((breadcrumb, index) => {
            return (
              <BreadcrumbItem key={index}>
                <NavLink className="inactive" to={breadcrumb.path}>
                  {breadcrumb.name}
                </NavLink>
              </BreadcrumbItem>
            );
          })}
        </Breadcrumb>
        <Outlet />
      </div>
    </TransferContextProvider>
  );
};

export default TransferDevicesBetweenTenants;
