import { useEffect, useContext } from 'react';
import { Alert, Button, Card, Input, Label } from 'reactstrap';
import { ErrorMessage, Formik, type FormikProps } from 'formik';
import { AuthContext } from '../../../contexts/AuthContext';
import { LogInValidationSchema } from '../../../schemas';
import { Link, useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';

interface IFormikLogInProps {
  username: string;
  password: string;
}

const LogIn: React.FC = () => {
  const { user, signIn, isLoading, error } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (error === 'idle-signout') {
      navigate('/idle-signout');
    }
    if (user?.challengeName !== undefined) {
      switch (user.challengeName) {
        case 'NEW_PASSWORD_REQUIRED': {
          navigate(`/new-password-required`);
          break;
        }
        case 'SMS_MFA': {
          navigate(`/sms-verification`);
          break;
        }
        default: {
          navigate('/reset-password');
        }
      }
    }
  }, [navigate, user, error]);
  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      onSubmit={(values) => {
        const { username, password } = values;
        signIn(username.trim(), password);
      }}
      validationSchema={LogInValidationSchema}
    >
      {({ handleSubmit, values, errors, handleChange, handleBlur }: FormikProps<IFormikLogInProps>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles['card-container']}>
              <h1 className="mx-auto mb-4">Log in</h1>
              {error.length > 0 ? (
                <Alert color="danger" name="idle-alert">
                  {error}
                </Alert>
              ) : null}

              <div>
                <Label className={'font-weight-bold mb-1' + (errors.username !== undefined ? ' text-danger' : '')}>
                  Email
                </Label>
                <Input
                  name="username"
                  id="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={errors.username !== undefined}
                />
                <ErrorMessage component="span" className={styles['error-message-tip']} name={'username'} />
              </div>
              <div>
                <Label className={'font-weight-bold mb-1' + (errors.password !== undefined ? ' text-danger' : '')}>
                  Password
                </Label>
                <Input
                  name="password"
                  id="password"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  invalid={errors.password !== undefined}
                />
                <ErrorMessage component="span" className={styles['error-message-tip']} name={'password'} />
              </div>
              <Button disabled={isLoading} type="submit" color="primary">
                Log in
              </Button>
              <Link className="align-self-center font-weight-bold my-1" to="/password-recovery">
                I have trouble logging in
              </Link>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default LogIn;
