import App from './App';
import { createRoot } from 'react-dom/client';
import './vendor_setup';
import './_theme/colors.css';
import './_theme/variables.css';
import './global.css';

const domNode = document.getElementById('root');
if (domNode != null) {
  const root = createRoot(domNode);
  root.render(<App />);
}
