import { useContext, useState, type ReactNode } from 'react';
import { Nav, NavItem, NavbarBrand, Collapse, NavbarToggler, Navbar, Button } from 'reactstrap';
import { AuthContext } from '../../contexts/AuthContext';
import styles from './styles.module.scss';

interface INavBarWrapper {
  children: ReactNode;
}

const NavBarWrapper = ({ children }: INavBarWrapper): JSX.Element => {
  const { signOut } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen: () => void = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles['app-container']}>
      <Navbar color="dark" dark expand="md" className={`m-0 p-0 ${styles['nav-container']}`}>
        <Nav className="navbar d-flex justify-content-between align-items-center container-fluid m-0 p-0">
          <NavbarBrand href={'/'} className={styles['brand-image']} />
          <NavbarToggler onClick={toggleIsOpen} className="bg-light" />
          <Collapse isOpen={isOpen} navbar>
            <NavItem className="ml-auto d-flex align-items-center justify-content-center">
              <Button
                onClick={() => {
                  signOut();
                }}
              >
                Log out
              </Button>
            </NavItem>
          </Collapse>
        </Nav>
      </Navbar>
      <div className={styles['content-container']}>{children}</div>
      <div className={styles['footer-container']}>InflowNet Admin App v{process.env.REACT_APP_VERSION}</div>
    </div>
  );
};

export default NavBarWrapper;
