import { useMemo, type Dispatch } from 'react';
import { Col, Label, Badge, Spinner, Card } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { mapTransferStatusToLabelText } from '../../utils';
import { useQuery } from 'react-query';
import { axiosInstance } from '../../api/axios';
import DeviceIcon from '../../static_assets/svg/device_normal.svg';
import {
  type IFailedTransferItem,
  type ISuccessfulTransferItem,
  type ITransferListItem,
} from '../transfer-list-item/TransferListItem';

import styles from './styles.module.scss';

const TransferStepThree = ({ setCurrentStep }: { setCurrentStep: Dispatch<number> }): JSX.Element => {
  const location = useLocation();

  const { data: transferInfo, isLoading } = useQuery<ITransferListItem>(
    ['transfer-info', location.hash],
    async () => {
      const result = await axiosInstance.get(
        `/transfer_devices_between_tenants/get_operations_for_transfer_devices_between_tenants/${location.hash.substring(
          1,
          location.hash.length,
        )}`,
      );

      if (result.data.status === 'pending') {
        setCurrentStep(3);
      } else {
        setCurrentStep(4);
      }

      return result.data;
    },
    {
      staleTime: 0,
      refetchOnMount: true,
    },
  );

  const statusColor = useMemo(() => {
    switch (transferInfo?.status) {
      case 'pending': {
        return styles['status-badge-pending'];
      }
      case 'finished': {
        return styles['status-badge-finished'];
      }
      case 'failed': {
        return styles['status-badge-failed'];
      }
      default:
        return '';
    }
  }, [transferInfo?.status]);

  if (isLoading) {
    return <Spinner type="border" />;
  }

  if (transferInfo === undefined) {
    return <h1>Couldnt show data, wrong id supplied</h1>;
  }

  return (
    <>
      <Col className="mb-2">
        <Card className={`${statusColor} ${styles['status-badge']}`}>
          <span>
            <b>Status: </b> {mapTransferStatusToLabelText(transferInfo.status)}
          </span>
        </Card>
      </Col>

      <Col className="mt-5">
        {transferInfo.status === 'finished' ? (
          <>
            <Col>
              <Label className="font-weight-bold mb-1">Transferred</Label>
              <Badge className={`text-white p-1 ml-2 bg-success`}>{transferInfo.succeeded_count}</Badge>
            </Col>
            <Col>
              {transferInfo.succeeded?.map((transferedDevice: ISuccessfulTransferItem, index: number) => {
                return (
                  <Col key={index} className="mt-3">
                    <img alt="device-img" src={DeviceIcon} className="pr-1" />
                    <Label className="mb-1">{transferedDevice.serial_number}</Label>
                    <Badge key={index} className={'text-white p-1 ml-2 bg-success'}>
                      ok
                    </Badge>
                  </Col>
                );
              })}
            </Col>

            <Col>
              <Label className="font-weight-bold mb-1 mt-5">Not transferred</Label>
              {transferInfo.failed_count > 0 ? (
                <Badge className={`text-white p-1 ml-2 bg-danger`}>{transferInfo.failed_count}</Badge>
              ) : null}
            </Col>
            <Col>
              {transferInfo.failed?.map((notTransferedDevice: IFailedTransferItem, index: number) => {
                return (
                  <Col key={index} className="mt-3">
                    <img alt="device-img" src={DeviceIcon} className="pr-1" />
                    <Label className="mb-1">{notTransferedDevice.serial_number}</Label>
                    <Badge key={index} className={'text-white p-1 ml-2 bg-danger'}>
                      {notTransferedDevice.error}
                    </Badge>
                  </Col>
                );
              })}
            </Col>

            <Label className="font-weight-bold mb-1 mt-5">Logs</Label>
            <Col>
              <code className={styles['code-card']}>
                <pre id="deviceSerialNumbers">{JSON.stringify(transferInfo, null, 2)}</pre>
              </code>
            </Col>
            <div className={styles.devider}></div>
            <Col className="d-flex align-items-end justify-content-end mb-3">
              <Link to="/commands/transfer-devices-between-tenants" className="btn btn-secondary align-self-end">
                Back to list
              </Link>
            </Col>
          </>
        ) : null}
      </Col>
    </>
  );
};

export default TransferStepThree;
