import { useContext, useMemo } from 'react';
import { Col } from 'reactstrap';
import { TransferContext } from '../../contexts/TransferContext';

import TransferProgressItem, { type TransferStep } from '../transfer-progress-item/TransferProgressItem';
import styles from './styles.module.css';

const TransferProgress = (): JSX.Element => {
  const { currentStep } = useContext(TransferContext);
  const progressItemStatuses = useMemo<TransferStep[]>((): TransferStep[] => {
    switch (currentStep) {
      case 1: {
        return ['ongoing', 'off', 'off'];
      }
      case 2: {
        return ['done', 'ongoing', 'off'];
      }
      case 3: {
        return ['done', 'done', 'ongoing'];
      }
      default: {
        return ['done', 'done', 'done'];
      }
    }
  }, [currentStep]);
  return (
    <Col className={styles['progress-container']}>
      <TransferProgressItem status={progressItemStatuses[0]} text="1. Transfer Information" />
      <TransferProgressItem status={progressItemStatuses[1]} text="2. Preview & confirmation" />
      <TransferProgressItem status={progressItemStatuses[2]} text="3. Status" />
    </Col>
  );
};

export default TransferProgress;
