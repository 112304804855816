import { useContext, useMemo } from 'react';
import { Col, Row, ListGroup, Label, Spinner } from 'reactstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { axiosInstance } from '../../api/axios';
import { useQuery } from 'react-query';
import TransferListItem, { type ITransferListItem } from '../transfer-list-item/TransferListItem';
import ListPagination from '../list-pagination/ListPagination';
import { convertStringToNumber } from '../../utils';
import { TransferContext } from '../../contexts/TransferContext';

import styles from './styles.module.css';

interface IPagination {
  total_results: number;
  current_offset: number;
  current_limit: number;
}

interface ITransferList {
  pagination: IPagination;
  data: ITransferListItem[];
}

const TransferDevicesBetweenTenantsTable: React.FC = () => {
  const { initNewTransfer } = useContext(TransferContext);

  const [searchParams] = useSearchParams();

  const { pageNumber, perPage, search } = useMemo(() => {
    const { pageNumber, perPage, search } = Object.fromEntries(searchParams.entries());
    return {
      pageNumber: pageNumber !== undefined ? convertStringToNumber(pageNumber) : 1,
      perPage: perPage !== undefined ? convertStringToNumber(perPage) : 10,
      search: search !== undefined ? search : '',
    };
  }, [searchParams]);

  const { data: transferData, isLoading } = useQuery<ITransferList, Error>(
    ['transferData', pageNumber, perPage, search],
    async () => {
      const response = await axiosInstance.get(
        '/transfer_devices_between_tenants/get_operations_for_transfer_devices_between_tenants',
        {
          params: {
            offset: (pageNumber - 1) * perPage,
            limit: perPage,
          },
        },
      );

      return response.data;
    },
    {
      refetchOnMount: true,
      staleTime: 0,
    },
  );

  return (
    <Row className={styles.content + ' d-flex flex-column'}>
      <Col className={styles.header}>
        <div className={styles['header-container']}>
          <h1 className={styles['header-text']}>Transfer devices between tenants</h1>
          <Link
            onClick={() => {
              initNewTransfer();
            }}
            to={'/commands/transfer-devices-between-tenants/transfer'}
            className={styles['transfer-link']}
          >
            <i className="fa-solid fa-plus transfer"></i>
            <span>Transfer</span>
          </Link>
        </div>
        <div className={styles['header-container']}>
          <span className={styles.historical}>Historical</span>
        </div>
      </Col>
      {isLoading ? (
        <Spinner type="border" />
      ) : (
        <>
          <Col>
            <ListGroup className={'list-unstyled mb-4'}>
              {transferData?.data !== undefined && transferData.data.length > 0 ? (
                transferData?.data.map((listItem, index) => {
                  const {
                    id,
                    author,
                    title,
                    status,
                    failed_count: failedCount,
                    succeeded_count: succeededCount,
                    created_at: createdAt,
                  } = listItem;
                  return (
                    <TransferListItem
                      key={index}
                      id={id}
                      author={author}
                      title={title}
                      status={status}
                      failed_count={failedCount}
                      succeeded_count={succeededCount}
                      created_at={createdAt}
                    />
                  );
                })
              ) : (
                <Label>No items found</Label>
              )}
            </ListGroup>
          </Col>
          <Col>
            <ListPagination
              maxPageNumber={transferData !== undefined ? transferData?.pagination.total_results / perPage + 1 : 0}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default TransferDevicesBetweenTenantsTable;
