import Routes from './routes/Routes';
import ReactQueryProvider from './hoc/ReactQueryProvider';
import UserContextProvider from './contexts/AuthContext';
import './App.css';
const App: React.FC = () => {
  return (
    <ReactQueryProvider>
      <UserContextProvider>
        <div className="fullscreen">
          <Routes />
        </div>
      </UserContextProvider>
    </ReactQueryProvider>
  );
};

export default App;
