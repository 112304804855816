import { useContext } from 'react';
import { ErrorMessage, Formik, type FormikProps } from 'formik';
import { Alert, Button, Card, Input, Label } from 'reactstrap';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../../contexts/AuthContext';
import { ResetPasswordValidationSchema } from '../../../schemas';

import styles from './styles.module.scss';

interface IFormikResetPasswordProps {
  username: string;
  password: string;
  confirmPassword: string;
  code: string;
}

const ResetPassword: React.FC = () => {
  const { resetPassword, error, isLoading } = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const urlUsername: null | string = searchParams.get('email');
  const urlCode: null | string = searchParams.get('code');
  return (
    <Formik
      initialValues={{
        username: urlUsername !== null ? urlUsername : '',
        password: '',
        confirmPassword: '',
        code: urlCode !== null ? urlCode : '',
      }}
      onSubmit={(values) => {
        const { username, password, code } = values;
        resetPassword(username, password, code);
      }}
      validationSchema={ResetPasswordValidationSchema}
    >
      {({
        handleSubmit,
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
      }: FormikProps<IFormikResetPasswordProps>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card className={styles['card-container']}>
              <h1 className="mx-auto mb-4">Reset Password</h1>
              <div>
                <span className="font-weight-bold">Requirements</span>
                <ul className={'pl-2 mt-2 mb-1 ' + styles['list-elements']}>
                  <li>8 characters or more</li>
                  <li>small and large letter (a-z, A-Z)</li>
                  <li>at least one digit (0-9)</li>
                  <li>at least one special character</li>
                </ul>
              </div>
              {error.length > 0 ? (
                <Alert color="danger" name="idle-alert">
                  {error}
                </Alert>
              ) : null}
              {urlUsername === null ? (
                <div>
                  <Label
                    className={
                      'font-weight-bold mb-1' +
                      (touched.username === true && errors.username !== undefined ? ' text-danger' : '')
                    }
                  >
                    Email
                  </Label>
                  <Input
                    name="username"
                    id="username"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.username === true && errors.username !== undefined}
                  />
                  <ErrorMessage component="span" className={styles['error-message-tip']} name={'username'} />
                </div>
              ) : null}

              <div>
                <Label
                  className={
                    'font-weight-bold mb-1' +
                    (touched.password === true && errors.password !== undefined ? ' text-danger' : '')
                  }
                >
                  New password
                </Label>
                <Input
                  name="password"
                  id="password"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  invalid={touched.password === true && errors.password !== undefined}
                />
                <ErrorMessage component="span" className={styles['error-message-tip']} name={'password'} />
              </div>
              <div>
                <Label
                  className={
                    'font-weight-bold mb-1' +
                    (touched.confirmPassword === true && errors.confirmPassword !== undefined ? ' text-danger' : '')
                  }
                >
                  New password confirmation
                </Label>
                <Input
                  name="confirmPassword"
                  id="confirmPassword"
                  type="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmPassword}
                  invalid={touched.confirmPassword === true && errors.confirmPassword !== undefined}
                />
                <ErrorMessage component="span" className={styles['error-message-tip']} name={'confirmPassword'} />
              </div>
              {urlCode === null ? (
                <div>
                  <Label
                    className={
                      'font-weight-bold mb-1' +
                      (touched.code === true && errors.code !== undefined ? ' text-danger' : '')
                    }
                  >
                    Code
                  </Label>
                  <Input
                    name="code"
                    id="code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.code === true && errors.code !== undefined}
                  />
                  <ErrorMessage component="span" className={styles['error-message-tip']} name={'code'} />
                </div>
              ) : null}

              <Button disabled={isLoading} type="submit" color="primary">
                Reset
              </Button>
            </Card>
          </form>
        );
      }}
    </Formik>
  );
};

export default ResetPassword;
