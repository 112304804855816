import { Navigate, Route, Routes } from 'react-router-dom';
import NavBarWrapper from '../../components/nav-bar/NavBarWrapper';
import TransferDevicesBetweenTenants from '../../containers/commands/transfer-devices-between-tenants/TransferDevicesBetweenTenants';
import MembersManagement from '../../containers/commands/members-management/MembersManagement';
import TransferDevicesBetweenTenantsTransfer from '../../components/transfer-devices-between-tenants-transfer/TransferDevicesBetweenTenantsTransfer';
import TransferDevicesBetweenTenantsTable from '../../components/transfer-devices-between-tenants-table/TransferDevicesBetweenTenantsTable';
import { useIdleTimer } from 'react-idle-timer';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { configureInterceptors } from '../../api/axios';

const PrivateRoutes: React.FC = () => {
  const { user, signOut } = useContext(AuthContext);
  const token = user?.getSignInUserSession()?.getIdToken()?.getJwtToken();

  configureInterceptors(token);

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: () => {
      signOut('idle-signout');
    },
  });

  return (
    <NavBarWrapper>
      <Routes>
        <Route path="*" element={<Navigate replace to={'/commands'} />} />
        <Route path="/" element={<Navigate replace to={'/commands'} />} />
        <Route path="/commands">
          <Route index element={<Navigate replace to={'/commands/transfer-devices-between-tenants'} />} />
          <Route path="transfer-devices-between-tenants" element={<TransferDevicesBetweenTenants />}>
            <Route index element={<TransferDevicesBetweenTenantsTable />} />
            <Route path="transfer" element={<TransferDevicesBetweenTenantsTransfer />} />
          </Route>
          <Route path="members-management" element={<MembersManagement />} />
        </Route>
      </Routes>
    </NavBarWrapper>
  );
};

export default PrivateRoutes;
