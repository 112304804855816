import { type TransferStatus, type DeviceTransferStatus } from '../components/transfer-list-item/TransferListItem';
import { type DeviceStatus } from '../components/transfer-steps/TransferStepTwo';

export const mapPathToName = (path: string): string | undefined => {
  switch (path) {
    case 'transfer-devices-between-tenants': {
      return 'Transfer devices between tenants';
    }
    case 'transfer': {
      return 'Transfer';
    }
    case 'commands': {
      return 'Commands';
    }
    default: {
      return undefined;
    }
  }
};

export const mapTransferStatusToLabelText = (status: TransferStatus | DeviceTransferStatus): string => {
  switch (status) {
    case 'pending': {
      return 'in progress';
    }
    default: {
      return status;
    }
  }
};

export const mapDeviceStateToText = (status: DeviceStatus): string => {
  switch (status) {
    case 'not_transferable': {
      return 'issues found';
    }
    case 'not_found': {
      return 'not found';
    }
    default: {
      return status;
    }
  }
};

export const mapDeviceStateToBgColor = (
  status: TransferStatus | DeviceTransferStatus | DeviceStatus | string,
): string => {
  switch (status) {
    case 'ok':
    case 'moved':
    case 'transferable': {
      return 'bg-success';
    }
    case 'pending':
    case 'not found':
    case 'not_transferable':
    case 'issues': {
      return 'bg-warning text-dark';
    }
    case 'failed': {
      return 'bg-danger';
    }
    default: {
      return 'bg-secondary';
    }
  }
};

export const groupByToMap = <T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q): Map<Q, T[]> =>
  array.reduce((map, value, index, array) => {
    const key = predicate(value, index, array);
    map.get(key)?.push(value) ?? map.set(key, [value]);
    return map;
  }, new Map<Q, T[]>());

export const groupBy = <T>(
  array: T[],
  predicate: (value: T, index: number, array: T[]) => string,
): Record<string, T[]> =>
  array.reduce<Record<string, T[]>>((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {});

export const restrictToValues = (value: number, max: number, min: number): number => {
  return Math.min(Math.max(value, min), max);
};

export const convertStringToNumber = (value: string): number => {
  return +value.replace(/[^0-9]/g, '');
};

export const formatDateToString = (date: Date): string => {
  return formatDateToYearMonthDay(date) + ' ' + formatDateToTime(date);
};

export const formatDateToYearMonthDay = (date: Date): string => {
  const year = date.getFullYear().toString();
  const month = formatDateNumberToString(date.getMonth() + 1);
  const day = formatDateNumberToString(date.getDate());

  return [year, month, day].join('/');
};

export const formatDateToTime = (date: Date): string => {
  const minutes = formatDateNumberToString(date.getMinutes());
  const hour = formatDateNumberToString(date.getHours());

  return [hour, minutes].join(':');
};

function formatDateNumberToString(num: number): string {
  if (num < 10) {
    return '0' + num.toString();
  }
  return num.toString();
}
