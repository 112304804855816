import { ErrorMessage, Formik, type FormikProps } from 'formik';
import { useContext } from 'react';
import { Alert, Button, Card, Input, Label } from 'reactstrap';
import ChallengeGuard from '../../../components/challenge-guard/ChallengeGuard';
import { SmsVerificationSchema } from '../../../schemas';
import { AuthContext } from '../../../contexts/AuthContext';

import styles from './styles.module.scss';

interface IFormikResetPasswordProps {
  code: string;
}

const SmsVerification: React.FC = () => {
  const { verifySms, error, isLoading } = useContext(AuthContext);

  return (
    <ChallengeGuard>
      <Formik
        initialValues={{
          code: '',
        }}
        onSubmit={(values) => {
          const { code } = values;
          verifySms(code);
        }}
        validationSchema={SmsVerificationSchema}
      >
        {({ handleSubmit, touched, errors, handleChange, handleBlur }: FormikProps<IFormikResetPasswordProps>) => {
          return (
            <form onSubmit={handleSubmit}>
              <Card className={styles['card-container']}>
                <h2 className="mx-auto mb-4">Check your phone</h2>

                <p className={styles.text}>A code was sent to your phone</p>

                {error.length > 0 ? (
                  <Alert color="danger" name="idle-alert">
                    {error}
                  </Alert>
                ) : null}
                <div>
                  <Label
                    className={
                      'font-weight-bold mb-1' +
                      (touched.code === true && errors.code !== undefined ? ' text-danger' : '')
                    }
                  >
                    Enter 6 digit code
                  </Label>
                  <Input
                    name="code"
                    id="code"
                    placeholder="******"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={touched.code === true && errors.code !== undefined}
                  />
                  <ErrorMessage component="span" className={styles['error-message-tip']} name={'code'} />
                </div>

                <Button disabled={isLoading} type="submit" color="primary">
                  Reset
                </Button>
              </Card>
            </form>
          );
        }}
      </Formik>
    </ChallengeGuard>
  );
};
export default SmsVerification;
