import { useContext, useMemo } from 'react';
import { Col, Row, ListGroupItem, Button, Badge } from 'reactstrap';
import { TransferContext } from '../../contexts/TransferContext';
import { mapDeviceStateToBgColor, mapTransferStatusToLabelText, formatDateToString } from '../../utils/index';
import styles from './styles.module.css';

export type TransferStatus = 'pending' | 'finished' | 'failed';
export type DeviceTransferStatus = 'moved' | 'failed';
type FailedErrorCode = number;

export interface ISuccessfulTransferItem {
  serial_number: string;
}

export interface IFailedTransferItem {
  action: 'return';
  serial_number: string;
  error: FailedErrorCode;
}

interface ITransferAuthor {
  email: string;
  first_name: string;
  last_name: string;
  id: string;
}

export interface ITransferListItem {
  id: string;
  author: ITransferAuthor;
  title: string;
  status: TransferStatus;
  failed_count: number;
  failed?: IFailedTransferItem[];
  succeeded_count: number;
  succeeded?: ISuccessfulTransferItem[];
  created_at: string;
}

const TransferListItem = ({
  id,
  author,
  title,
  status,
  failed_count: failedCount,
  succeeded_count: succeededCount,
  created_at: createdAt,
}: ITransferListItem): JSX.Element => {
  const { handlePreviewTransferInformation } = useContext(TransferContext);

  const date = useMemo(() => formatDateToString(new Date(createdAt)), [createdAt]);

  const badges: Array<{ status: TransferStatus | DeviceTransferStatus; count: string }> = useMemo(() => {
    if (status === 'pending') {
      return [{ status, count: '' }];
    }
    return [
      { status, count: '' },
      { status: 'moved', count: succeededCount.toString() },
      { status: 'failed', count: failedCount.toString() },
    ];
  }, [status, failedCount, succeededCount]);

  return (
    <ListGroupItem className="p-1">
      <Row className="p-1">
        <Col>
          <Row>
            <span>
              <b>{title}</b>
            </span>
          </Row>
          <Col>
            <span>{`by ${author.first_name} ${author.last_name} at ${date}`}</span>
            <div className="d-inline-block ms-2">
              {badges.map((badge, index) => {
                return (
                  <Badge key={index} className={`text-white p-1 ml-1 mr-1 ${mapDeviceStateToBgColor(badge.status)}`}>
                    {badge.count} {mapTransferStatusToLabelText(badge.status)}
                  </Badge>
                );
              })}
            </div>
          </Col>
        </Col>
        <Col className={styles['list-item-button']}>
          <Button
            onClick={() => {
              handlePreviewTransferInformation(id);
            }}
            className={`${styles['view-logs-button']}`}
          >
            View logs
          </Button>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export default TransferListItem;
