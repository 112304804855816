import { useMemo } from 'react';
import { Row } from 'reactstrap';
import styles from './styles.module.css';

export type TransferStep = 'ongoing' | 'off' | 'done';

interface ITransferProgressItem {
  status: TransferStep;
  text?: string;
}

const TransferProgressItem = ({ status, text }: ITransferProgressItem): JSX.Element => {
  const circleStyle = useMemo(() => {
    switch (status) {
      case 'ongoing': {
        return styles['progress-circle-ongoing'];
      }
      case 'done': {
        return styles['progress-circle-done'];
      }
      default: {
        return styles['progress-circle-off'];
      }
    }
  }, [status]);

  return (
    <Row className={styles['main-container'] + ' ' + (status === 'done' ? styles['bg-done'] : '')}>
      <div className={circleStyle + ' ' + styles['circle-defaults']}>
        {status === 'done' ? <i className={'fa-solid fa-check ' + styles.check}></i> : null}
      </div>
      <span className={styles.text}>{text}</span>
    </Row>
  );
};

export default TransferProgressItem;
