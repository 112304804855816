import { useCallback, useMemo } from 'react';
import { Pagination, PaginationItem, PaginationLink, Input, Col, FormGroup, Label } from 'reactstrap';
import { restrictToValues, convertStringToNumber } from '../../utils/index';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';

interface IListPagination {
  maxPageNumber: number;
}

export const RESULTS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
const VISIBLE_PAGE_OPTIONS = 3;

const ListPagination = ({ maxPageNumber = 1 }: IListPagination): JSX.Element | null => {
  const normalizedPageNumber = Math.floor(maxPageNumber);
  const [searchParams, setSearchParams] = useSearchParams();

  const { pageNumber, perPage } = useMemo(() => {
    const pageNumber = searchParams.get('pageNumber');
    const perPage = searchParams.get('perPage');
    return {
      pageNumber:
        pageNumber !== null ? restrictToValues(convertStringToNumber(pageNumber), normalizedPageNumber, 1) : 1,
      perPage: perPage !== null ? convertStringToNumber(perPage) : RESULTS_PER_PAGE_OPTIONS[0],
    };
  }, [normalizedPageNumber, searchParams]);

  const handleChangePageNumber = useCallback(
    (newPageNumber: number): void => {
      if (newPageNumber === +pageNumber) {
        return;
      }
      searchParams.set('pageNumber', `${Math.max(newPageNumber, 1)}`);
      setSearchParams(searchParams);
    },
    [pageNumber, searchParams, setSearchParams],
  );

  const handleChangePerPage = useCallback(
    (newPerPage: number): void => {
      if (newPerPage === +perPage) {
        return;
      }
      searchParams.set('perPage', `${Math.max(newPerPage, 1)}`);
      searchParams.set('pageNumber', '1');
      setSearchParams(searchParams);
    },
    [perPage, searchParams, setSearchParams],
  );

  const middlePart = useCallback(() => {
    const firstOption = Math.floor((pageNumber - 1) / VISIBLE_PAGE_OPTIONS) * VISIBLE_PAGE_OPTIONS + 1;

    if (firstOption < 0) {
      return null;
    }

    const numberedOptions = [];

    for (let i = 0; i < VISIBLE_PAGE_OPTIONS; i++) {
      const optionValue = firstOption + i;

      if (optionValue > normalizedPageNumber) {
        break;
      }

      numberedOptions.push(
        <PaginationItem key={`${optionValue}-${i}`} name="page-option" active={pageNumber === optionValue}>
          <PaginationLink
            aria-label={`middle-part-label-${i}`}
            onClick={() => {
              handleChangePageNumber(optionValue);
            }}
            tag="button"
          >
            {optionValue}
          </PaginationLink>
        </PaginationItem>,
      );
    }
    return numberedOptions;
  }, [pageNumber, normalizedPageNumber, handleChangePageNumber]);

  return (
    <Col className="d-flex justify-content-between">
      <Pagination className={'d-flex align-items-center ' + styles['custom-pagination']}>
        {normalizedPageNumber >= 3 ? (
          <>
            <PaginationItem>
              <PaginationLink
                first
                tag="button"
                onClick={() => {
                  handleChangePageNumber(1);
                }}
              />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                previous
                tag="button"
                onClick={() => {
                  handleChangePageNumber(Math.max(pageNumber - 1, 1));
                }}
              />
            </PaginationItem>
          </>
        ) : null}

        {middlePart()}

        {normalizedPageNumber >= 3 ? (
          <>
            <PaginationItem>
              <PaginationLink
                next
                className={styles['no-labels-link']}
                tag="button"
                onClick={() => {
                  handleChangePageNumber(Math.min(pageNumber + 1, normalizedPageNumber));
                }}
              />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                last
                className={styles['no-labels-link']}
                tag="button"
                onClick={() => {
                  handleChangePageNumber(normalizedPageNumber);
                }}
              />
            </PaginationItem>
          </>
        ) : null}
      </Pagination>

      <FormGroup className={`d-flex align-items-center ${styles['per-page']}`}>
        <Label for="results-per-page" className={styles['results-per-page-label']}>
          per page
        </Label>
        <Input
          onChange={(newValue) => {
            handleChangePerPage(+newValue.target.value);
          }}
          className={styles['per-page-input']}
          value={perPage}
          type="select"
          name="results-per-page"
          id="results-per-page"
        >
          {RESULTS_PER_PAGE_OPTIONS.map((optionValue, i) => (
            <option value={optionValue} key={`${optionValue}-${i}`}>
              {optionValue}
            </option>
          ))}
        </Input>
      </FormGroup>
    </Col>
  );
};

export default ListPagination;
